import React from 'react';
import styles from 'styles/modules/NotFound.module.scss';

const NotFoundPage = () => {
    return (
        <div className={styles.NotFound}>
            Sorry, something went wrong, our team is on it.
            <br />
            If needed, please contact{' '}
            <a href="mailto:support@newspage.co.uk">
                support@newspage.co.uk
            </a>{' '}
            for assistance.
        </div>
    );
};

export default NotFoundPage;
